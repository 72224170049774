import React from 'react'
import { Card, Text, IconButton } from 'theme-ui'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import Section from '@components/Section'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'

const ContactInfo = () => {
  const { phone, address, email } = useSiteMetadata()

  const parseEmail = str => {
    const indexOfAt = str.indexOf('@')
    console.log('indexOfAt: ', indexOfAt)
    const parsedEmail = str.slice(0, indexOfAt) + '\r\n' + str.slice(indexOfAt)
    return parsedEmail
  }

  return (
    <Section aside title='Advertise Here'>
      <Card variant='paper'>
        {/* <Text variant='p'>Got a question?</Text> */}
        <Text variant='p'>
          Feel free to reach out. 
        </Text>
        {/* {phone && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaPhone />
            </IconButton>
            {phone}
          </Text>
        )} */}
        {email && (
          <div style={{ display: 'flex' }}>
            <IconButton variant='simple' role='presentation'>
              <FaEnvelope />
            </IconButton>

            <Text>{parseEmail(email)}</Text>
          </div>
        )}
        {address && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaLocationArrow />
            </IconButton>
            {address}
          </Text>
        )}
      </Card>
    </Section>
  )
}

export default ContactInfo
